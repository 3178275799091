'use client';

/* eslint-disable no-console */

import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { provider, auth } from './firebase';
import type { APIResponse } from '@/types';
import type { FirebaseError } from 'firebase/app';

const apiUrl = 'https://polaris.reverbs.com.br';
// const apiUrl = process.env.NEXT_PUBLIC_API_URL || 'https://polaris.reverbs.com.br';

export async function signInWithGoogle() {
    try {
        const userCreds = await signInWithPopup(auth, provider);
        const idToken = await userCreds.user.getIdToken();

        const response = await fetch('/api/auth/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ idToken }),
        });
        const resBody = (await response.json()) as unknown as APIResponse<string>;
        if (response.ok && resBody.success) {
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error signing in with Google', error);
        return false;
    }
}

export async function signInWithForm(
    email: string,
    password: string,
): Promise<{
    success: boolean;
    displayName: string | null;
    err?: string | null | FirebaseError | unknown;
}> {
    try {
        const userCreds = await signInWithEmailAndPassword(auth, email, password);
        const idToken = await userCreds.user.getIdToken();
        const displayName = userCreds.user.displayName || 'User';

        const response = await fetch('/api/auth/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ idToken }),
        });
        const resBody = (await response.json()) as unknown as APIResponse<string>;
        if (response.ok && resBody.success) {
            return { success: true, displayName, err: null };
        }
        return {
            success: false,
            displayName: null,
            err: 'Erro não esperado, contate o suporte',
        };
    } catch (error) {
        console.error('Error signing in with email and password', error);
        return { success: false, displayName: null, err: error };
    }
}

export async function signOut() {
    localStorage.clear();
    sessionStorage.clear();
    try {
        // Sign out from Firebase
        await auth.signOut();

        // Call the server to invalidate the session
        const response = await fetch('/api/auth/signout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const resBody = (await response.json()) as APIResponse<string>;

        if (response.ok && resBody.success) {
            const cacheBuster = Date.now();
            window.location.href = `/login?cb=${cacheBuster}`;
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error signing out', error);
        return false;
    }
}

export async function createAccount(email: string, password: string, userName: string) {
    try {
        const response = await fetch(`${apiUrl}/v1/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
                userName,
                pictureUrl:
                    'https://64.media.tumblr.com/127bac9110cc14251b97f36e703ad0d1/tumblr_inline_p85xbgBxKF1sw2q6u_250.png',
            }),
        });
        const resBody = await response.json();
        console.log(response);
        console.log(resBody);
        if (!resBody.error && resBody.uid) {
            await signInWithForm(email, password);
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error creating account with email and password', error);
        return false;
    }
}

export async function createProject(
    userId: string | undefined,
    userIdToken: string | null,
    projectName: string,
    projectWebsite: string,
    projectImageUrl: string,
    planType: 'basic' | 'complete',
    frontendUrl: string,
    language: string,
) {
    console.log('aqui chegou assim ', projectName, projectWebsite, planType);
    try {
        const response = await fetch(`${apiUrl}/v1/users/${userId}/projects`, {
            cache: 'no-store',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userIdToken}`,
            },
            body: JSON.stringify({
                projectName,
                projectWebsite,
                projectImageUrl:
                    'https://64.media.tumblr.com/127bac9110cc14251b97f36e703ad0d1/tumblr_inline_p85xbgBxKF1sw2q6u_250.png',
                planType,
                frontendUrl,
                language,
            }),
        });
        const resBody = (await response.json()) as unknown as APIResponse<string>;
        if (response.ok && resBody.success) {
            return resBody;
        }
        return resBody;
    } catch (error) {
        console.error('Error creating a project', error);
        return false;
    }
}

export async function getProjects(userId: string | undefined, userIdToken: string | null | undefined): Promise<any> {
    if (typeof window !== 'undefined') {
        try {
            const response = await fetch(`${apiUrl}/v1/users/${userId}/projects`, {
                cache: 'no-store',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userIdToken}`,
                },
            });
            const test = await response.json();
            console.log('getting projects auth.ts ', test);
            return test;
        } catch (error) {
            console.error('Error getting projects', error);
            return false;
        }
    } else return false;
}

export async function getProject(
    userId: string | undefined,
    userIdToken: string | null,
    projectId: string,
): Promise<any> {
    if (typeof window !== 'undefined') {
        try {
            const response = await fetch(`${apiUrl}/v1/users/${userId}/projects/${projectId}`, {
                cache: 'no-store',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userIdToken}`,
                },
            });
            const test = await response.json();
            console.log('getting project auth.ts ', test);
            return test;
        } catch (error) {
            console.error('Error getting project', error);
            return false;
        }
    } else return false;
}

export const serverConfig = {
    useSecureCookies: process.env.USE_SECURE_COOKIES === 'true',
    firebaseApiKey: 'AIzaSyD5-R3Svp7C_ASSNMxNjxm269EJdbsVvwY',
    serviceAccount: {
        projectId: 'pelagic-gist-420715',
        clientEmail: 'firebase-adminsdk-lbyvj@pelagic-gist-420715.iam.gserviceaccount.com',
        privateKey:
            '-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDmOoKVVFW0xBEx\ntPARKagLL0QAtVCg/CI236iRL3ErJ6AUJ0+ntKI5PCxHho7lcGM6OBRV8SHZzh9y\ny395b6YrJmBkp2IPhg+ThASk2bDplaOkViqCImuul0QYJh8X9iPufMDRBmvCDjJr\nfI6MPhhTcG5vxD7FrjNceefHBZxWFFLx7d0m+I8K74foAKfEa52Ruq6wkm/p11g1\n5qVyjR6tvj3moSPZiJVkjsGTq6HwHg09W6VMRFAshYnkkVTIIkIvfEurawQHefjE\nrH5wAut1AgCKrZ2kLI6rzHqh8IdnDVZbYV0kT7bftQHTvIAY6SOoiSJlviiFo6UX\nUgDArl8hAgMBAAECggEABsZxxZR65bQP5/kxx+WEjhi03Pr4/ZXR6XC9SJF/eHRE\n8hbikZY/oGTQjkXL0n1zVBkmow1SsFLQKtwJezDBkHtDgq7I1Fle/7m2u8WyvrsC\nOvG0tEnwRo3pyXaku1spXUkzUOtqFCbADdj3ZnHk3B6+xz/8k2twFEB7pUgZXNCT\nwFnpq3daliH27o584eIRmI2dF7lFJBxb8ozB1y6CfX1amZobUYaOsVz0AFHp+Cdo\nyCwu7jRlkejhv3j992bX/fFQcuFJo9IBg+58ceoYDB0pv44DfGcpgDFzXU8zRY3G\n7cIbr8RFhS8DTrR7gbCcJVA5Ym4odxK2AUZhIuOhAQKBgQD77b8qc3kfy38BBvSW\n2VxwflDX4S4zSkCWvpKqis8hKzz5+96znb5Gx5O3Ycdcg4P2KUEHvYSKCKoayiH5\nZ7P7eIgA2DU77Zlyr40va88PsgR0Jdkhu/849JZKjuM7g3A3cG23qbme1vty51SP\nhzgu26mbb4Gqlsu91ymNtQS1YQKBgQDp8vzefL2ZKUyDbNR1Fd+b9iI5hY7lg5CT\nYqCIvi6gxsTiB4C6QAVplgJXfpu95APXHyAxfcgd1q1NtL4+eH9602K+ckfZCz1x\n5e8f+W3aX6AabUHam/ujOFrMiXHd/7bfw3DkIXm7p7CMyY6nfbcmfMAbzlOaICG+\nmg2MepRBwQKBgQCpE9bI1tbjz99dAR4GX7r6hMwdFrO7GIpgoWYtrxt0V2CFJdVB\nFw0s06T/3k++d0WJYAaU+0yIl2gSsrY6fK7+htZAOtr/bWce4VMQEHrXPdJfYUdo\nrJON1hlH0L4QoxYdHweDzBUX1ZDMfRTzfvzl9ebOuvd7VdlHf7ATuvl+AQKBgQCO\nw5puRx8IbTxk4y/Pd5aqPQxrn8Yw5xV962zdpXkc4c9Zw4xZnCHxafpp3Dg8K/3v\n8+2YZhKNqui9B7QkuTHKCV8zqtePap2HePJwx6yy2zH/jnbmadOoqgY21RUojRjS\n+hhVDtyTmGRTtdgY+82dbflpvoIkBsGw55+m/1KjwQKBgQCSScr8KDbEkiMSHl6U\nhPaTx3KGtYvdN+rARz6KG8O1hInDHp/YzaV7HG+NvhG8ImUz75ZtxepWAQ0Ow4Mf\nWFMFQUY57gVgTE2EX/44VKB0bZ8A1ksKn/cvqfd3oXDZ8YXxvIE5eOHUEYzNYMSy\nJ8sF0Gm634B5l4N9SFMDhxuVJw==\n-----END PRIVATE KEY-----\n',
    },
};

export const authConfig = {
    apiKey: serverConfig.firebaseApiKey,
    cookieName: '__session',
    cookieSignatureKeys: [
        process.env.AUTH_COOKIE_SIGNATURE_KEY_CURRENT!,
        process.env.AUTH_COOKIE_SIGNATURE_KEY_PREVIOUS!,
    ],
    cookieSerializeOptions: {
        path: '/',
        httpOnly: true,
        secure: serverConfig.useSecureCookies, // Set this to true on HTTPS environments
        sameSite: 'lax' as const,
        maxAge: 60 * 60 * 24 * 5, // one day
    },
    serviceAccount: serverConfig.serviceAccount,
    // Set to false in Firebase Hosting environment due to https://stackoverflow.com/questions/44929653/firebase-cloud-function-wont-store-cookie-named-other-than-session
    enableMultipleCookies: false,
};
